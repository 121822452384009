import {
  ButtonProps,
  Dialog,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import { Img } from "./Img";
import {
  APP_ICONS,
  FlagIcon,
  GOOGLE_ICON,
  SellBagIcon,
  SmCircleChatIcon,
  TelesaleIcon,
} from "Assets/icons";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import { REACT_APP_GG_APP_ID } from "constants/schemas";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { apiRegisterWithGg } from "services/api/user";
import { useLoading } from "providers/loading";
import { MouseEvent, useCallback, useState } from "react";
import { useLanguageContext } from "./LanguageProvider";
import useAuth from "hooks/useAuth";
import useNavigate from "hooks/useNavigate";
import { encodeBase64 } from "helpers/base64";
import { useIntl } from "react-intl";
import { Link, OpenTabLink, StyledA, TextSemibold } from "./Text";
import { getAllDataInQueryString, objectToQueryString } from "helpers/search";
import useChat from "hooks/useChat";
import { IntlMsg, ReportForm } from "Components";
import useUser from "hooks/useUser";
import {
  StyledAddButton,
  StyledBaseButton,
  StyledButton,
  StyledCancelButton,
  StyledConfirmButton,
  StyledGetQuotesButton,
  StyledGrayButton,
  StyledLgAcceptButton,
  StyledMailButton,
  StyledRegisterButton,
  StyledReportButton,
  StyledTextButton,
  StyledWhiteButton,
} from "./Button.styles";

const GoogleButton = (
  props: ButtonProps & {
    hideText?: boolean;
    onCallback?: any;
    text?: string;
    onCustomConfirm?: (data: any) => void;
  }
) => {
  const { text = "login_gg", onCustomConfirm } = props;
  const { directKeepSearch, useSearchParams } = useNavigate();
  const redirect_uri = `${window.location.protocol}//${window.location.host}`;
  const { setLoading } = useLoading();
  const { language } = useLanguageContext();
  const intl = useIntl();
  const { onGetUserInformation, onDirectNextPageOfLogin } = useAuth();

  const onConfirm = useCallback((data: any) => {
    if (onCustomConfirm) {
      onCustomConfirm(data);
    } else {
      const { code } = data || {};
      setLoading(true);
      const queryData = getAllDataInQueryString(useSearchParams);

      apiRegisterWithGg({
        code,
        language,
        redirect_uri,
        ...queryData,
        check_signup: true,
      })
        .then((res) => {
          setLoading(false);
          if (props.onCallback) {
            props.onCallback(res);
          } else {
            if (res?.data?.is_signed_up) {
              onGetUserInformation()
                .then((err) => {
                  onDirectNextPageOfLogin();
                })
                .catch((err) => {
                  console.log(false);
                });
            } else {
              const searchParams = new URLSearchParams({
                [SEARCH_PARAMS.uid]: code,
                [SEARCH_PARAMS.mail]: res.data.user,
                [SEARCH_PARAMS.username]: res.data.full_name,
              }).toString();
              directKeepSearch(PATHS.register, searchParams);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  return (
    <LoginSocialGoogle
      client_id={REACT_APP_GG_APP_ID || ""}
      redirect_uri={redirect_uri}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      access_type="offline"
      onResolve={({ data }: IResolveParams) => {
        onConfirm(data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <StyledButton fullWidth {...props}>
        <Img src={GOOGLE_ICON} alt="icon" />
        {!props.hideText && (
          <TextSemibold>{intl.formatMessage({ id: text })}</TextSemibold>
        )}
      </StyledButton>
    </LoginSocialGoogle>
  );
};

const ConfirmButton = (props: ButtonProps) => {
  return <StyledConfirmButton {...props} />;
};

export const SendMessageButton = ({
  identity,
  shorter,
  ...rest
}: { identity?: string; shorter?: boolean } & ButtonProps) => {
  const { onAddChatToShowList } = useChat();
  const t = useIntl();

  const onClickSendMsg = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onAddChatToShowList(identity);
  };

  return shorter ? (
    <IconButton className="chat-icon fit" onClick={onClickSendMsg}>
      <SmCircleChatIcon />
    </IconButton>
  ) : (
    <WhiteButton
      onClick={onClickSendMsg}
      endIcon={<SmCircleChatIcon />}
      {...rest}
    >
      {t.formatMessage({ id: "send_message" })}
    </WhiteButton>
  );
};

export const ReportButton = ({
  mobile,
  type,
  title,
  reference_name,
}: {
  mobile?: boolean;
  type: string;
  title?: string;
  reference_name?: string;
}) => {
  const t = useIntl();
  const [showReport, setShowReport] = useState(false);

  const onCloseReport = () => setShowReport(false);

  return (
    <>
      {!mobile && (
        <IconButton className="fit" onClick={() => setShowReport(true)}>
          <FlagIcon />
        </IconButton>
      )}
      {mobile && (
        <StyledReportButton
          endIcon={<FlagIcon />}
          onClick={() => setShowReport(true)}
        >
          {t.formatMessage({ id: "report" })}
        </StyledReportButton>
      )}

      <Dialog open={showReport} onClose={onCloseReport}>
        <ReportForm
          onClose={onCloseReport}
          {...{ mobile, type, title, reference_name }}
        />
      </Dialog>
    </>
  );
};

export const GetQuotesButton = (props: ButtonProps) => (
  <Link to={PATHS.quickRfq}>
    <StyledGetQuotesButton
      variant="text"
      startIcon={<Img src={APP_ICONS.postRfq} />}
      {...props}
    >
      <IntlMsg id="get_quotes" />
    </StyledGetQuotesButton>
  </Link>
);

export const RegisterButton = () => {
  const { onDirectRegister } = useAuth();
  const onClickRegister = () => {
    onDirectRegister();
  };

  return (
    <StyledRegisterButton onClick={onClickRegister}>
      <IntlMsg id="JoinForFree" />
    </StyledRegisterButton>
  );
};

const AddButton = (props: ButtonProps) => {
  return <StyledAddButton {...props} />;
};

const CancelButton = (props: ButtonProps) => {
  return <StyledCancelButton {...props} />;
};

const AcceptButton = (props: ButtonProps) => {
  return <StyledLgAcceptButton {...props} />;
};

export const LgAcceptButton = (props: ButtonProps) => {
  return <StyledLgAcceptButton {...props} />;
};

const BaseButton = (props: ButtonProps) => {
  return <StyledBaseButton {...props} />;
};

const TextButton = (props: ButtonProps) => {
  return <StyledTextButton variant="text" {...props} />;
};

const YellowButton = (props: ButtonProps) => {
  return <StyledConfirmButton {...props} />;
};

export const GreenButton = (props: ButtonProps) => {
  return <StyledLgAcceptButton {...props} />;
};

export const GrayButton = (props: ButtonProps) => {
  return <StyledGrayButton {...props} />;
};

export const WhiteButton = (props: ButtonProps) => {
  return <StyledWhiteButton {...props} />;
};

const SellingButton = (props: ButtonProps) => {
  const t = useIntl();
  const { isLoggedIn } = useUser();
  return (
    <StyledA href={PATHS.bizDomain}>
      <StyledTextButton
        variant="text"
        style={{ color: "var(--green-600)" }}
        startIcon={<SellBagIcon />}
        {...props}
      >
        {t.formatMessage({ id: isLoggedIn ? "seller_center" : "StartSelling" })}
      </StyledTextButton>
    </StyledA>
  );
};

export const MailButton = (props: IconButtonProps) => {
  return (
    <OpenTabLink href={PATHS.contactUs}>
      <StyledMailButton children={<TelesaleIcon />} {...props} />
    </OpenTabLink>
  );
};

export {
  GoogleButton,
  ConfirmButton,
  AddButton,
  CancelButton,
  AcceptButton,
  BaseButton,
  TextButton,
  YellowButton,
  SellingButton,
};
