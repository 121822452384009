import { PLAN_NAMES, SUPPLIER_PLANS } from "constants/data";
import dayjs from "dayjs";
import { OptionType } from "types";
import { UserPlanType } from "types/user";

export const removeSameOption = (options: OptionType[]) => [
  ...new Map(options.map((item: OptionType) => [item.value, item])).values(),
];

export function removeUndefinedFields<T extends Record<string, any>>(
  object: T
): Partial<T> {
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === "undefined") {
      delete object[key];
    }
  });
  return object;
}

export const followRel = (no_follow: number) =>
  no_follow === 1 ? "nofollow" : undefined;

export const getCurrentPlan = (plan?: UserPlanType) => {
  const now = dayjs();

  if (!plan) return null;

  return {
    ...(SUPPLIER_PLANS.find((p) => p.label === plan?.plan_name) ||
      SUPPLIER_PLANS.find((p) => p.label === PLAN_NAMES.free)),
    ...plan,
    isExpired: dayjs(plan.end_date).isBefore(now),
  };
};
