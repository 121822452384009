import { Box, BoxProps, Stack, styled } from "@mui/material";
import { Container } from "./Container";

const Main = (props: BoxProps) => {
  return (
    <StyledMain {...props}>
      <Container>
        <Stack spacing={{ xs: 1, md: 2 }}>{props.children}</Stack>
      </Container>
    </StyledMain>
  );
};

const StyledMain = styled(Box)(({ theme }) => ({
  backgroundColor: "var(--bg-color)",
  padding: "16px 0",
  [theme.breakpoints.down("md")]: {
    padding: "8px 0",
    ".MuiContainer-root": {
      paddingLeft: 8,
      paddingRight: 8,
    },
    ".title": {
      fontSize: 16,
    },
  },
}));

export default Main;
