import { Skeleton, Stack, StackProps, styled } from "@mui/material";

const LoadingSkeleton = (props: StackProps & { count?: number }) => {
  const { count = 3 } = props;

  return (
    <StyledStack spacing={1} alignItems="center" width="100%" {...props}>
      {[...Array(count)].map((count, i) => (
        <Skeleton className="loading_box" key={i} />
      ))}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({
  "& .loading_box": {
    height: 24,
    width: 164,
  },
});

export default LoadingSkeleton;
