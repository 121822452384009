import {
  Avatar,
  Box,
  Dialog,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FiUser } from "react-icons/fi";

import {
  Img,
  SmTextMd,
  SmTextSemibold,
  StyledATag,
  XsText,
  XsTextMd,
} from "Components";
import useAuth from "hooks/useAuth";
import { useIntl } from "react-intl";
import { useMemo, useState } from "react";
import { PATHS } from "constants/routes";
import {
  BagIcon,
  CartIcon,
  CrownIcon,
  LogoutIcon,
  SendIcon,
  ShipIcon,
  SmCrownIcon,
  SmLockIcon,
  SmNoteIcon,
  SmPackageIcon,
  SmSettingIcon,
  SmTargetIcon,
} from "Assets/icons";
import useUser from "hooks/useUser";
import { menuCountDataSelector } from "redux/selectors/common";
import { COLORS, MAX_NOTI_CHAR_QLT } from "constants/layout";
import { PlanChip } from "../Plan";
import BuyerVerfifyTooltip from "Components/tooltip/BuyerVerifyTooltip";
import { StyledAuthButton, StyledMenu } from "./AuthBar.styles";
import { formatImg } from "utils/helpers";
import ConfirmButtons from "Components/button/ConfirmButtons";
import SupplierVerfifyTooltip from "Components/tooltip/SupplierVerifyTooltip";
import { getCurrentPlan } from "helpers/format";

const AuthBar = ({ hideUserName = false }) => {
  const { user, currentPlan, userContactQuote } = useUser();
  const { buyer, supplier } = user;

  const t = useIntl();
  const { isLoggedIn, onLogout, onDirectLogin } = useAuth();
  const menuCountStoreData = useSelector(menuCountDataSelector);
  const [openDialog, setOpenDialog] = useState(false);
  const [menuConfig, setMenuConfig] = useState<{
    open: boolean;
    anchorEl?: any;
  }>({
    open: false,
    anchorEl: undefined,
  });

  const formatUserName = (name = "") => {
    return name && name?.length > 0 && name[0];
  };

  const handleClickLogin = (e: any) => {
    if (isLoggedIn) {
      setMenuConfig({ open: true, anchorEl: e.currentTarget });
    } else {
      onDirectLogin();
    }
  };

  const onConfirmLogout = () => {
    onLogout();
    onCloseDialog();
    setMenuConfig({ open: false });
  };

  const supplierMenuItems = useMemo(
    () => [
      {
        label: "all_buying_leads",
        href: PATHS.rfqMarket,
        icon: SmTargetIcon,
      },
      {
        label: "list_your_product",
        href: PATHS.supplierCreateProduct,
        icon: SmPackageIcon,
      },
      {
        label: "become_premium_supplier",
        href: PATHS.pricing,
        icon: SmCrownIcon,
        labelStyles: { color: COLORS.green[600] },
        iconStyles: { stroke: COLORS.green[600] },
      },
      {
        label: "quotation_sent",
        href: PATHS.supplierMyQuotations,
        icon: BagIcon,
      },
      {
        label: "inquiries_received",
        href: PATHS.supplierInquiries,
        count: menuCountStoreData?.count_inquiries,
        icon: SmNoteIcon,
      },
      {
        label: "edit_your_profile",
        href: PATHS.supplierSetting,
        icon: SmSettingIcon,
      },
    ],
    [menuCountStoreData]
  );

  const buyerMenuItems = useMemo(
    () => [
      {
        label: "get_quotes_from_suppliers",
        href: PATHS.quickRfq,
        icon: SmTargetIcon,
      },
      {
        label: "my_request_for_quotation",
        href: PATHS.requestQuotation,
        icon: SendIcon,
        openNewTab: true,
      },
      {
        label: "inquiries_sent",
        href: PATHS.inquiries,
        icon: SmNoteIcon,
      },
      {
        label: "quotations_received",
        href: PATHS.receivedQuotation,
        count: menuCountStoreData?.count_quotations,
        icon: BagIcon,
      },
      {
        label: "edit_your_profile",
        href: PATHS.profile,
        icon: SmSettingIcon,
      },
    ],
    [menuCountStoreData]
  );

  const commonMenuItems = useMemo(
    () => [
      {
        label: "change_your_password",
        href: PATHS.resetPassword,
        icon: SmLockIcon,
      },
      {
        label: "sign_out",
        event: () => setOpenDialog(true),
        icon: LogoutIcon,
      },
    ],
    []
  );

  const onCloseMenu = () => setMenuConfig({ open: false });

  const onCloseDialog = () => setOpenDialog(false);

  return (
    <>
      <StyledAuthButton
        variant="text"
        startIcon={
          isLoggedIn ? (
            <Avatar alt="icon" src={formatImg(user?.avatar, 1)}>
              {user?.first_name && formatUserName(user?.first_name)}
            </Avatar>
          ) : (
            <FiUser />
          )
        }
        onClick={handleClickLogin}
        hideUserName={hideUserName}
      >
        {!hideUserName && (
          <Stack>
            <SmTextSemibold className="username" align="left">
              {isLoggedIn ? user?.first_name : t.formatMessage({ id: "login" })}
            </SmTextSemibold>
            {isLoggedIn && (
              <Typography fontSize={11} className="quote-text" fontWeight={500}>
                {t.formatMessage(
                  {
                    id:
                      userContactQuote > 1
                        ? "value_contact_credits"
                        : "value_contact_credit",
                  },
                  { v: userContactQuote }
                )}
              </Typography>
            )}
          </Stack>
        )}
      </StyledAuthButton>

      {/* Dropdown content */}
      <StyledMenu
        open={menuConfig.open}
        anchorEl={menuConfig.anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {/* User informations */}
        <Box className="user-info">
          <Stack direction="row" spacing={1}>
            <Img src={formatImg(user.avatar, 3)} className="avatar" />
            <Stack spacing={0.25} maxWidth="calc(100% - 68px)">
              <SmTextMd className="cutoff-text oneline">
                {user.first_name}
              </SmTextMd>
              <XsText className="ellipsis-text" color={COLORS.gray[500]}>
                {user?.email}
              </XsText>
              <XsText fontSize={10} className="credit-text">
                {t.formatMessage(
                  {
                    id:
                      userContactQuote > 1
                        ? "value_contact_credits"
                        : "value_contact_credit",
                  },
                  { v: <span>{userContactQuote}</span> }
                )}
              </XsText>
            </Stack>
          </Stack>
        </Box>

        {/* Supplier informations */}
        <StyledATag href={PATHS.supplierDesk}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            my={1}
            justifyContent="center"
          >
            <ShipIcon />
            <SmTextMd color={COLORS.green[600]}>
              {t.formatMessage({ id: "selling" })}
            </SmTextMd>
            <PlanChip plan={getCurrentPlan(supplier?.plan)?.plan_name} />
            <SupplierVerfifyTooltip
              data={{
                is_biz_verified: supplier?.is_biz_verified,
              }}
            />
          </Stack>
        </StyledATag>
        <Divider />
        {supplierMenuItems?.map((menuItem, index) => {
          const { labelStyles, label, iconStyles, href } = menuItem;
          const count = menuItem.count;
          return (
            <MenuItem key={index} href={href} component="a">
              <menuItem.icon width={15} {...iconStyles} />
              <XsTextMd ml={0.5} {...labelStyles}>
                {t.formatMessage({ id: label })}
              </XsTextMd>
              {Number(count) > 0 && (
                <p className={"badge-number"}>
                  {Math.min(count, MAX_NOTI_CHAR_QLT)}
                  {count > MAX_NOTI_CHAR_QLT ? "+" : ""}
                </p>
              )}
            </MenuItem>
          );
        })}

        {/* Buyer informations */}
        <StyledATag href={PATHS.overview}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            my={1}
            justifyContent="center"
          >
            <CartIcon />
            <SmTextMd color={COLORS.green[600]}>
              {t.formatMessage({ id: "buying" })}
            </SmTextMd>
            <PlanChip plan={getCurrentPlan(buyer?.plan)?.plan_name} />
            <BuyerVerfifyTooltip
              data={{ is_biz_verified: buyer?.plan.is_biz_verified }}
            />
          </Stack>
        </StyledATag>
        <Divider />
        {buyerMenuItems?.map((menuItem, index) => {
          const { label, href } = menuItem;
          const count = menuItem.count;
          return (
            <MenuItem key={index} href={href} component="a">
              <menuItem.icon width={15} />
              <XsTextMd ml={0.5}>{t.formatMessage({ id: label })}</XsTextMd>
              {Number(count) > 0 && (
                <p className={"badge-number"}>
                  {Math.min(count, MAX_NOTI_CHAR_QLT)}
                  {count > MAX_NOTI_CHAR_QLT ? "+" : ""}
                </p>
              )}
            </MenuItem>
          );
        })}

        <Divider />
        <Stack pb={1.5}>
          {commonMenuItems?.map((menuItem, index) => {
            const { label, href, event } = menuItem;
            return (
              <MenuItem key={index} href={href} component="a" onClick={event}>
                <menuItem.icon width={15} />
                <XsTextMd ml={0.5}>{t.formatMessage({ id: label })}</XsTextMd>
              </MenuItem>
            );
          })}
        </Stack>
      </StyledMenu>

      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        PaperProps={{ sx: { width: "100%", maxWidth: 360 } }}
      >
        <Stack spacing={1} p={2}>
          <Typography>{t.formatMessage({ id: "confirm_logout" })}</Typography>
          <ConfirmButtons
            onCancle={onCloseDialog}
            onConfirm={onConfirmLogout}
          />
        </Stack>
      </Dialog>
    </>
  );
};

export default AuthBar;
