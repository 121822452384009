import { SVGProps } from "react";

export function UploadIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_16330_91368)">
        <path
          d="M5.25009 13.4999C4.3143 13.4999 3.41683 13.1443 2.75513 12.5114C2.09342 11.8785 1.72168 11.02 1.72168 10.1249C1.72168 9.2298 2.09342 8.37135 2.75513 7.73842C3.41683 7.10548 4.3143 6.7499 5.25009 6.7499C5.4711 5.76529 6.11766 4.90001 7.04753 4.34444C7.50795 4.06934 8.02407 3.87856 8.56642 3.78299C9.10877 3.68741 9.66672 3.68891 10.2084 3.7874C10.7501 3.88589 11.265 4.07945 11.7236 4.35701C12.1821 4.63458 12.5755 4.99072 12.8812 5.4051C13.1868 5.81948 13.3988 6.28399 13.505 6.7721C13.6112 7.26021 13.6095 7.76237 13.5001 8.2499H14.2501C14.9463 8.2499 15.614 8.52646 16.1062 9.01875C16.5985 9.51103 16.8751 10.1787 16.8751 10.8749C16.8751 11.5711 16.5985 12.2388 16.1062 12.7311C15.614 13.2233 14.9463 13.4999 14.2501 13.4999H13.5001"
          stroke="#667085"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 11.25L9 9L11.25 11.25"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9V15.75"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16330_91368">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
