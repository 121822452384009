import {
  styled,
  Stack,
  MenuItem,
  Box,
  Dialog,
  LinearProgress,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SmText, SmTextMd } from "Components/Text";
import { useNotify } from "providers/notify";
import { formatError } from "helpers/error";
import { APP_ICONS, CheckIcon, DeleteIcon, RegenerateIcon } from "Assets/icons";
import Row from "Components/Row";
import { Img } from "Components/Img";
import { BaseButton } from "Components/Button";
import Disclaimer from "Components/Disclaimer";
import { FiInfo } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { setGeneratingAI } from "redux/actions/layout";
import { FakeInput } from "Components/Input";
import { removeHTMLTags } from "helpers/string";
import { COLORS } from "constants/layout";

type AIGenerateInputProps = {
  useForm: any;
  fieldName: string;
  label?: string;
  requiredlabel?: boolean;
  children?: ReactNode;
  isValid?: boolean;
  apiGenerate?: any;
  onClickGenerate?: () => void;
  onClickApplyBonus?: () => void;
  removeHTML?: boolean;
};

const AIGenerateInput = ({
  useForm,
  fieldName,
  label,
  requiredlabel,
  children,
  isValid,
  apiGenerate,
  onClickGenerate = () => {},
  onClickApplyBonus = () => {},
  removeHTML = false,
}: AIGenerateInputProps) => {
  const intl = useIntl();
  const { setNotify } = useNotify();
  const dispatch = useDispatch();

  const { getValues, setValue } = useForm;
  const [isGenerating, setIsGenerating] = useState(false);
  const [openGenerate, setOpenGenerate] = useState(false);
  const [progress, setProgress] = useState(78);
  const [tmpValue, setTmpValue] = useState<string>("");
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const onClickDiscard = () => {
    setOpenGenerate(false);
    dispatch(setGeneratingAI(false));
  };

  const onClickApply = () => {
    setValue(fieldName, (getValues(fieldName) || "") + "\n" + tmpValue, {
      shouldValidate: true,
    });
    // setValue(fieldName, tmpValue, {
    //   shouldValidate: true,
    // });
    onClickDiscard();
    onClickApplyBonus?.();
  };

  const onClickAction = (action: string) => {
    switch (action) {
      case "apply":
        onClickApply();
        break;
      case "regenerate":
        onGenerateRequest();
        break;

      case "discard":
        onClickDiscard();
        break;
      default:
        break;
    }
  };

  const onGenerateRequest = () => {
    onClickGenerate?.();
    if (isValid && apiGenerate) {
      dispatch(setGeneratingAI(true));
      setIsGenerating(true);
      setProgress(0);
      setOpenGenerate(true);
      apiGenerate()
        .then((res: any) => {
          const data = res.data.message?.data;
          setTmpValue(removeHTML ? removeHTMLTags(data) : data);
          setProgress(100);
        })
        .catch((err: any) => {
          console.log(err);
          setOpenGenerate(false);
          setNotify({
            open: true,
            msg: formatError(err).message,
            type: "error",
          });
        })
        .finally(() => {
          setIsGenerating(false);
        });
    }
  };
  const actions = [
    {
      label: intl.formatMessage({ id: "apply" }),
      icon: <CheckIcon />,
      value: "apply",
    },
    {
      label: intl.formatMessage({ id: "regenerate" }),
      icon: <RegenerateIcon />,
      value: "regenerate",
    },
    {
      label: intl.formatMessage({ id: "discard" }),
      icon: <DeleteIcon />,
      value: "discard",
    },
  ];
  useEffect(() => {
    if (progress < 100) {
      const autoCountProgress = setInterval(() => {
        setProgress((progress) => Math.min(progress + 10, 90));
      }, 1000);

      return () => clearInterval(autoCountProgress);
    }
  }, [isGenerating]);

  const onClickInfoIcon = () => {
    setShowDisclaimer(true);
  };

  const onCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  return (
    <Stack spacing={1} className="input">
      <Row spacing={1}>
        <SmText className={"input-label" + (requiredlabel ? " required" : "")}>
          {label}
        </SmText>
        <GenerateButton onClick={onGenerateRequest}>
          {intl.formatMessage({
            id: isGenerating ? "generating" : "generate_request",
          })}
          <Img src={APP_ICONS.sparkless} />
        </GenerateButton>
      </Row>

      <div style={{ display: openGenerate ? "none" : "block" }}>{children}</div>

      {openGenerate && (
        <StyledAIGenerateInput
          justifyContent={isGenerating ? "center" : "flex-start"}
          className="generate-box"
        >
          <Box
            alignItems={"center"}
            height="calc(100% - 36px)"
            className="generate-box-main"
          >
            {progress === 100 && (
              <Stack className="generate-detail">
                <SmText className="generate-text">
                  {removeHTMLTags(tmpValue)}
                </SmText>

                <Box id="alert">
                  <Row p={2}>
                    <FiInfo onClick={onClickInfoIcon} cursor="pointer" />
                    <SmText>{intl.formatMessage({ id: "disclaimer" })}</SmText>
                  </Row>
                  <Stack className="actions">
                    {actions.map((action, id) => (
                      <MenuItem
                        onClick={() => onClickAction(action.value)}
                        key={id}
                      >
                        <Row>
                          {action.icon}
                          <SmTextMd>{action.label}</SmTextMd>
                        </Row>
                      </MenuItem>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            )}
          </Box>
          {isGenerating && (
            <Row justifyContent="center" width="100%">
              <LinearProgress
                variant="determinate"
                className="progress"
                value={progress}
              />
              <SmTextMd>{progress}%</SmTextMd>
            </Row>
          )}
        </StyledAIGenerateInput>
      )}

      <Dialog
        open={showDisclaimer}
        onClose={onCloseDisclaimer}
        PaperProps={{
          sx: {
            maxWidth: 544,
            width: "100%",
          },
        }}
      >
        <Disclaimer onClose={onCloseDisclaimer} />
      </Dialog>
    </Stack>
  );
};

export default AIGenerateInput;

const GenerateButton = styled(BaseButton)({
  borderRadius: 13,
  width: "fit-content",
  padding: "5px 12px",
  lineHeight: 1,
  fontSize: 10,
  border: "none",
  "&,:hover": { backgroundColor: COLORS.green[600] },
  color: "#fff",
  img: {
    height: 12,
    marginLeft: 4,
  },
});

const StyledAIGenerateInput = styled(FakeInput)({
  zIndex: 1,
  flex: 1,
  minHeight: 48,
  backgroundColor: "#fff",
  ".input": {
    width: "100%",
  },
  ".progress": {
    width: "100%",
    maxWidth: 278,
    borderRadius: 4,
    height: 8,
    ".MuiLinearProgress-bar": {
      backgroundColor: "#008934",
      borderRadius: 4,
    },
  },
  ".generate-detail": {
    width: "100%",
    minHeight: "100%",
  },
  ".generate-detail, .actions": {
    zIndex: 1,
    whiteSpace: "pre-line",
  },
  ".generate-text": {
    padding: "16px",
    backgroundColor: "rgb(229, 246, 253)",
    maxHeight: 300,
    overflow: "hidden",
    overflowY: "scroll",
  },
  ".generate-box-main": {
    display: "flex",
  },

  "#alert": {
    position: "relative",
    ".actions": {
      padding: "16px",
      width: "100%",
      maxWidth: 260,
      marginTop: 8,
      border: "1px solid #F2F4F7",
      borderRadius: 4,
    },
  },
});
