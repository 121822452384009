import { SVGProps } from "react";

export function TargetIcon({
  width = 18,
  height = 18,
  viewBox = "0 0 18 18",
  stroke = "#F79009",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M8.16667 5.74997C8.58333 5.66663 8.91667 5.16663 8.75 4.74997C8.66667 4.3333 8.16667 3.99997 7.75 4.16663C5.5 4.66663 4 6.66663 4 8.99997C4 11.75 6.25 14 9 14C11.5833 14 13.75 12 14 9.41663C14.0833 8.99997 13.6667 8.5833 13.25 8.49997C12.8333 8.41663 12.4167 8.8333 12.3333 9.24997C12.1667 10.9166 10.6667 12.3333 9 12.3333C7.16667 12.3333 5.66667 10.8333 5.66667 8.99997C5.66667 7.41663 6.66667 6.0833 8.16667 5.74997Z"
        fill={stroke}
      />
      <path
        d="M16.5 8.16657C16 8.16657 15.6667 8.4999 15.6667 8.9999C15.6667 12.6666 12.6667 15.6666 9.00002 15.6666C5.33335 15.6666 2.33335 12.6666 2.33335 8.9999C2.33335 5.4999 5.08335 2.58324 8.58335 2.33324C9.08335 2.33324 9.41669 1.91657 9.33335 1.41657C9.33335 0.916571 8.91669 0.583237 8.41669 0.666571C4.08335 0.999904 0.666687 4.58324 0.666687 8.9999C0.666687 13.5832 4.41669 17.3332 9.00002 17.3332C13.5834 17.3332 17.3334 13.5832 17.3334 8.9999C17.3334 8.4999 16.9167 8.16657 16.5 8.16657Z"
        fill={stroke}
      />
      <path
        d="M10.5834 6.24996L8.41669 8.41663C8.08335 8.74996 8.08335 9.24996 8.41669 9.58329C8.58335 9.74996 8.83335 9.83329 9.00002 9.83329C9.16669 9.83329 9.41669 9.74996 9.58335 9.58329L11.75 7.41663L13.1667 7.83329C13.25 7.83329 13.3334 7.83329 13.4167 7.83329C13.6667 7.83329 13.8334 7.74996 14 7.58329L17.25 4.24996C17.4167 4.08329 17.5 3.74996 17.5 3.49996C17.5 3.24996 17.25 2.99996 17 2.91663L15.5 2.41663L15 0.916626C14.9167 0.666626 14.6667 0.416626 14.4167 0.416626C14.1667 0.333293 13.8334 0.416626 13.6667 0.666626L10.4167 3.99996C10.1667 4.24996 10.0834 4.58329 10.25 4.83329L10.5834 6.24996ZM13.8334 2.83329L14.0834 3.41663C14.1667 3.66663 14.3334 3.83329 14.5834 3.91663L15.1667 4.16663L13.1667 6.16663L12.1667 5.83329L11.8334 4.83329L13.8334 2.83329Z"
        fill={stroke}
      />
    </svg>
  );
}

export function LgTargetIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={41}
      height={41}
      viewBox={"0 0 41 41"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M18.5326 14.2985C19.3659 14.1319 20.0326 13.1318 19.6992 12.2985C19.5326 11.4652 18.5326 10.7985 17.6992 11.1318C13.1992 12.1318 10.1992 16.1318 10.1992 20.7985C10.1992 26.2985 14.6992 30.7985 20.1992 30.7985C25.3659 30.7985 29.6992 26.7985 30.1992 21.6318C30.3659 20.7985 29.5326 19.9652 28.6992 19.7985C27.8659 19.6318 27.0326 20.4652 26.8659 21.2985C26.5326 24.6318 23.5326 27.4652 20.1992 27.4652C16.5326 27.4652 13.5326 24.4652 13.5326 20.7985C13.5326 17.6318 15.5326 14.9652 18.5326 14.2985Z"
        fill="#008934"
      />
      <path
        d="M35.1979 19.1317C34.1979 19.1317 33.5313 19.7984 33.5313 20.7984C33.5313 28.1317 27.5313 34.1317 20.1979 34.1317C12.8646 34.1317 6.86458 28.1317 6.86458 20.7984C6.86458 13.7984 12.3646 7.96506 19.3646 7.46506C20.3646 7.46506 21.0312 6.63173 20.8646 5.63173C20.8646 4.63173 20.0313 3.96506 19.0313 4.13173C10.3646 4.79839 3.53125 11.9651 3.53125 20.7984C3.53125 29.9651 11.0313 37.4651 20.1979 37.4651C29.3646 37.4651 36.8646 29.9651 36.8646 20.7984C36.8646 19.7984 36.0312 19.1317 35.1979 19.1317Z"
        fill="#008934"
      />
      <path
        d="M23.3646 15.2985L19.0312 19.6318C18.3646 20.2985 18.3646 21.2985 19.0312 21.9652C19.3646 22.2985 19.8646 22.4652 20.1979 22.4652C20.5312 22.4652 21.0312 22.2985 21.3646 21.9652L25.6979 17.6318L28.5312 18.4652C28.6979 18.4652 28.8646 18.4652 29.0312 18.4652C29.5312 18.4652 29.8646 18.2985 30.1979 17.9652L36.6979 11.2985C37.0312 10.9652 37.1979 10.2985 37.1979 9.7985C37.1979 9.2985 36.6979 8.7985 36.1979 8.63184L33.1979 7.63184L32.1979 4.63184C32.0312 4.13184 31.5312 3.63184 31.0312 3.63184C30.5312 3.46517 29.8646 3.63184 29.5312 4.13184L23.0312 10.7985C22.5312 11.2985 22.3646 11.9652 22.6979 12.4652L23.3646 15.2985ZM29.8646 8.46517L30.3646 9.63184C30.5312 10.1318 30.8646 10.4652 31.3646 10.6318L32.5312 11.1318L28.5312 15.1318L26.5312 14.4652L25.8646 12.4652L29.8646 8.46517Z"
        fill="#008934"
      />
    </svg>
  );
}

export function SmTargetIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox={"0 0 15 16"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.875 5.56241C7.1875 5.49991 7.4375 5.12491 7.3125 4.81241C7.25 4.49991 6.875 4.24991 6.5625 4.37491C4.875 4.74991 3.75 6.24991 3.75 7.99991C3.75 10.0624 5.4375 11.7499 7.5 11.7499C9.4375 11.7499 11.0625 10.2499 11.25 8.31241C11.3125 7.99991 11 7.68741 10.6875 7.62491C10.375 7.56241 10.0625 7.87491 10 8.18741C9.875 9.43741 8.75 10.4999 7.5 10.4999C6.125 10.4999 5 9.37491 5 7.99991C5 6.81241 5.75 5.81241 6.875 5.56241Z"
        fill="#667085"
      />
      <path
        d="M13.125 7.37493C12.75 7.37493 12.5 7.62493 12.5 7.99993C12.5 10.7499 10.25 12.9999 7.5 12.9999C4.75 12.9999 2.5 10.7499 2.5 7.99993C2.5 5.37493 4.5625 3.18743 7.1875 2.99993C7.5625 2.99993 7.8125 2.68743 7.75 2.31243C7.75 1.93743 7.4375 1.68743 7.0625 1.74993C3.8125 1.99993 1.25 4.68743 1.25 7.99993C1.25 11.4374 4.0625 14.2499 7.5 14.2499C10.9375 14.2499 13.75 11.4374 13.75 7.99993C13.75 7.62493 13.4375 7.37493 13.125 7.37493Z"
        fill="#667085"
      />
      <path
        d="M8.6875 5.9375L7.0625 7.5625C6.8125 7.8125 6.8125 8.1875 7.0625 8.4375C7.1875 8.5625 7.375 8.625 7.5 8.625C7.625 8.625 7.8125 8.5625 7.9375 8.4375L9.5625 6.8125L10.625 7.125C10.6875 7.125 10.75 7.125 10.8125 7.125C11 7.125 11.125 7.0625 11.25 6.9375L13.6875 4.4375C13.8125 4.3125 13.875 4.0625 13.875 3.875C13.875 3.6875 13.6875 3.5 13.5 3.4375L12.375 3.0625L12 1.9375C11.9375 1.75 11.75 1.5625 11.5625 1.5625C11.375 1.5 11.125 1.5625 11 1.75L8.5625 4.25C8.375 4.4375 8.3125 4.6875 8.4375 4.875L8.6875 5.9375ZM11.125 3.375L11.3125 3.8125C11.375 4 11.5 4.125 11.6875 4.1875L12.125 4.375L10.625 5.875L9.875 5.625L9.625 4.875L11.125 3.375Z"
        fill="#667085"
      />
    </svg>
  );
}
