import { SVGProps } from "react";

export function SettingIcon({
  width = 21,
  height = 21,
  viewBox = "0 0 21 21",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M12.7001 5.29974C12.5169 5.48666 12.4142 5.73798 12.4142 5.99974C12.4142 6.26149 12.5169 6.51281 12.7001 6.69974L14.3001 8.29974C14.487 8.48296 14.7383 8.58559 15.0001 8.58559C15.2619 8.58559 15.5132 8.48296 15.7001 8.29974L19.4701 4.52974C19.9729 5.64092 20.1252 6.87897 19.9066 8.07888C19.6879 9.27879 19.1088 10.3836 18.2464 11.246C17.3839 12.1084 16.2792 12.6876 15.0792 12.9062C13.8793 13.1248 12.6413 12.9726 11.5301 12.4697L4.6201 19.3797C4.22227 19.7776 3.68271 20.0011 3.1201 20.0011C2.55749 20.0011 2.01792 19.7776 1.6201 19.3797C1.22227 18.9819 0.998779 18.4423 0.998779 17.8797C0.998779 17.3171 1.22227 16.7776 1.6201 16.3797L8.5301 9.46974C8.02726 8.35855 7.87502 7.1205 8.09364 5.92059C8.31227 4.72068 8.89139 3.61589 9.75382 2.75346C10.6163 1.89102 11.721 1.3119 12.921 1.09328C14.1209 0.874651 15.3589 1.0269 16.4701 1.52974L12.7101 5.28974L12.7001 5.29974Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmSettingIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 15 16"
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M9.18747 4.43746C9.07295 4.55429 9.00881 4.71136 9.00881 4.87496C9.00881 5.03855 9.07295 5.19563 9.18747 5.31246L10.1875 6.31246C10.3043 6.42697 10.4614 6.49112 10.625 6.49112C10.7886 6.49112 10.9456 6.42697 11.0625 6.31246L13.4187 3.95621C13.733 4.6507 13.8281 5.42448 13.6915 6.17442C13.5549 6.92437 13.1929 7.61486 12.6539 8.15388C12.1149 8.6929 11.4244 9.05485 10.6744 9.19149C9.92449 9.32813 9.15071 9.23298 8.45622 8.91871L4.13747 13.2375C3.88883 13.4861 3.5516 13.6258 3.19997 13.6258C2.84834 13.6258 2.51111 13.4861 2.26247 13.2375C2.01383 12.9888 1.87415 12.6516 1.87415 12.3C1.87415 11.9483 2.01383 11.6111 2.26247 11.3625L6.58122 7.04371C6.26695 6.34921 6.17179 5.57544 6.30843 4.82549C6.44508 4.07555 6.80702 3.38505 7.34605 2.84603C7.88507 2.30701 8.57556 1.94506 9.3255 1.80842C10.0754 1.67178 10.8492 1.76693 11.5437 2.08121L9.19372 4.43121L9.18747 4.43746Z"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
