import { SVGProps } from "react";

export function GoalIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox={"0 0 20 20"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M9.16667 6.74997C9.58333 6.66663 9.91667 6.16663 9.75 5.74997C9.66667 5.3333 9.16667 4.99997 8.75 5.16663C6.5 5.66663 5 7.66663 5 9.99997C5 12.75 7.25 15 10 15C12.5833 15 14.75 13 15 10.4166C15.0833 9.99997 14.6667 9.5833 14.25 9.49997C13.8333 9.41663 13.4167 9.8333 13.3333 10.25C13.1667 11.9166 11.6667 13.3333 10 13.3333C8.16667 13.3333 6.66667 11.8333 6.66667 9.99997C6.66667 8.41663 7.66667 7.0833 9.16667 6.74997Z"
        fill="#344054"
      />
      <path
        d="M17.5001 9.16657C17.0001 9.16657 16.6667 9.4999 16.6667 9.9999C16.6667 13.6666 13.6667 16.6666 10.0001 16.6666C6.33342 16.6666 3.33341 13.6666 3.33341 9.9999C3.33341 6.4999 6.08342 3.58324 9.58342 3.33324C10.0834 3.33324 10.4167 2.91657 10.3334 2.41657C10.3334 1.91657 9.91675 1.58324 9.41675 1.66657C5.08342 1.9999 1.66675 5.58324 1.66675 9.9999C1.66675 14.5832 5.41675 18.3332 10.0001 18.3332C14.5834 18.3332 18.3334 14.5832 18.3334 9.9999C18.3334 9.4999 17.9167 9.16657 17.5001 9.16657Z"
        fill="#344054"
      />
      <path
        d="M11.5834 7.24996L9.41675 9.41663C9.08341 9.74996 9.08341 10.25 9.41675 10.5833C9.58341 10.75 9.83341 10.8333 10.0001 10.8333C10.1667 10.8333 10.4167 10.75 10.5834 10.5833L12.7501 8.41663L14.1667 8.83329C14.2501 8.83329 14.3334 8.83329 14.4167 8.83329C14.6667 8.83329 14.8334 8.74996 15.0001 8.58329L18.2501 5.24996C18.4167 5.08329 18.5001 4.74996 18.5001 4.49996C18.5001 4.24996 18.2501 3.99996 18.0001 3.91663L16.5001 3.41663L16.0001 1.91663C15.9167 1.66663 15.6667 1.41663 15.4167 1.41663C15.1667 1.33329 14.8334 1.41663 14.6667 1.66663L11.4167 4.99996C11.1667 5.24996 11.0834 5.58329 11.2501 5.83329L11.5834 7.24996ZM14.8334 3.83329L15.0834 4.41663C15.1667 4.66663 15.3334 4.83329 15.5834 4.91663L16.1667 5.16663L14.1667 7.16663L13.1667 6.83329L12.8334 5.83329L14.8334 3.83329Z"
        fill="#344054"
      />
    </svg>
  );
}
