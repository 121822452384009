import { SVGProps } from "react";

export function LockIcon({
  width = 20,
  height = 22,
  viewBox = "0 0 20 22",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M5 10V6C5 4.67392 5.52678 3.40215 6.46447 2.46447C7.40215 1.52678 8.67392 1 10 1C11.3261 1 12.5979 1.52678 13.5355 2.46447C14.4732 3.40215 15 4.67392 15 6V10M3 10H17C18.1046 10 19 10.8954 19 12V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19V12C1 10.8954 1.89543 10 3 10Z"
        stroke={color}
      />
    </svg>
  );
}

export function SmLockIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox={"0 0 15 15"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M4.375 6.875V4.375C4.375 3.5462 4.70424 2.75134 5.29029 2.16529C5.87634 1.57924 6.6712 1.25 7.5 1.25C8.3288 1.25 9.12366 1.57924 9.70971 2.16529C10.2958 2.75134 10.625 3.5462 10.625 4.375V6.875M3.125 6.875H11.875C12.5654 6.875 13.125 7.43464 13.125 8.125V12.5C13.125 13.1904 12.5654 13.75 11.875 13.75H3.125C2.43464 13.75 1.875 13.1904 1.875 12.5V8.125C1.875 7.43464 2.43464 6.875 3.125 6.875Z"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UnLockIcon({
  width = 25,
  height = 24,
  viewBox = "0 0 25 24",
  color = "#101828",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.5 10.9992C6.5 11.5515 6.94772 11.9992 7.5 11.9992C8.05228 11.9992 8.5 11.5515 8.5 10.9992H6.5ZM7.5 6.99918H8.5L8.5 6.99817L7.5 6.99918ZM16.42 6.19817C16.5299 6.73941 17.0578 7.08908 17.599 6.97918C18.1402 6.86928 18.4899 6.34142 18.38 5.80018L16.42 6.19817ZM5.5 11.9992H19.5V9.99918H5.5V11.9992ZM19.5 11.9992C20.0523 11.9992 20.5 12.4469 20.5 12.9992H22.5C22.5 11.3423 21.1569 9.99918 19.5 9.99918V11.9992ZM20.5 12.9992V19.9992H22.5V12.9992H20.5ZM20.5 19.9992C20.5 20.5515 20.0523 20.9992 19.5 20.9992V22.9992C21.1569 22.9992 22.5 21.656 22.5 19.9992H20.5ZM19.5 20.9992H5.5V22.9992H19.5V20.9992ZM5.5 20.9992C4.94772 20.9992 4.5 20.5515 4.5 19.9992H2.5C2.5 21.656 3.84315 22.9992 5.5 22.9992V20.9992ZM4.5 19.9992V12.9992H2.5V19.9992H4.5ZM4.5 12.9992C4.5 12.4469 4.94772 11.9992 5.5 11.9992V9.99918C3.84315 9.99918 2.5 11.3423 2.5 12.9992H4.5ZM8.5 10.9992V6.99918H6.5V10.9992H8.5ZM8.5 6.99817C8.499 6.00621 8.86662 5.04926 9.5315 4.31311L8.04725 2.97258C7.04994 4.07682 6.49851 5.51224 6.5 7.00018L8.5 6.99817ZM9.5315 4.31311C10.1964 3.57695 11.1111 3.11409 12.098 3.0144L11.897 1.02453C10.4166 1.17407 9.04456 1.86834 8.04725 2.97258L9.5315 4.31311ZM12.098 3.0144C13.0849 2.91471 14.0737 3.18529 14.8724 3.77362L16.0586 2.16336C14.8606 1.28087 13.3774 0.874992 11.897 1.02453L12.098 3.0144ZM14.8724 3.77362C15.671 4.36195 16.2226 5.22605 16.42 6.19817L18.38 5.80018C18.0839 4.342 17.2566 3.04585 16.0586 2.16336L14.8724 3.77362Z"
        fill={color}
      />
    </svg>
  );
}
