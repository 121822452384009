import { Container, Divider, Grid, Stack, styled } from "@mui/material";
import React, { memo } from "react";
import { SmText, StyledATag, StyledRouterA, TextMd } from "./Text";
import { useIntl } from "react-intl";
import { GrayButton, GreenButton, TextButton } from "./Button";
import { COLORS } from "constants/layout";
import Row from "./Row";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { objectToQueryString } from "helpers/search";
import { encodeBase64 } from "helpers/base64";
import { getUser } from "redux/selectors/user";
import { useSelector } from "react-redux";
import { UserType } from "types/user";
import useBreakpoints from "hooks/useBreakpoint";

type Props = {};

const questions = {
  what_is_freshdi: {
    title: "what_is_freshdi",
    description: "what_is_freshdi_description_1",
  },
  what_makes_freshdi_different: {
    title: "what_makes_freshdi_different",
    description: "what_makes_freshdi_different_description_1",
  },
  ai_different: {
    title: "why_is_freshdi_ai_different_from_traditional_platforms",
    descriptions: [
      "why_is_freshdi_ai_different_from_traditional_platforms_description_1",
      "why_is_freshdi_ai_different_from_traditional_platforms_description_2",
      "why_is_freshdi_ai_different_from_traditional_platforms_description_3",
      "why_is_freshdi_ai_different_from_traditional_platforms_description_4",
    ],
  },
  how_to_use_freshdi_ai: {
    title: "how_to_use_freshdi_ai",
    descriptions: [
      "how_to_use_freshdi_ai_description_1",
      "how_to_use_freshdi_ai_description_2",
      "how_to_use_freshdi_ai_description_3",
      "how_to_use_freshdi_ai_description_4",
    ],
  },
  q_and_a: {
    title: "q_and_a",
    descriptions: ["qa_question_1", "qa_question_2", "qa_question_3"],
  },
};

const FooterEnterprise = (props: Props) => {
  const t = useIntl();
  const user: UserType = useSelector(getUser);
  const { is_logged_in } = user || {};
  const { isMobile } = useBreakpoints();

  return (
    <StyledContainer maxWidth="xl">
      {(!is_logged_in || (user.is_supplier === 1 && is_logged_in)) && (
        <Stack
          mb={2}
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1, md: 2 }}
          justifyContent="center"
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <TextMd className="title">
            {t.formatMessage({ id: "are_you_supplier" })}
          </TextMd>
          <SmText className="description">
            {t.formatMessage({ id: "listing_product_company_to_attract" })}
          </SmText>
          <Row justifyContent="center" width={{ xs: "100%", md: "auto" }}>
            <StyledRouterA
              to={{
                pathname: PATHS.register,
                search: objectToQueryString({
                  [SEARCH_PARAMS.directUrl]: encodeBase64(window.location.href),
                }),
              }}
            >
              <GrayButton sx={{ whiteSpace: "nowrap" }}>
                {t.formatMessage({ id: "list_my_company" })}
              </GrayButton>
            </StyledRouterA>
            {isMobile && (
              <StyledATag href={PATHS.buyers}>
                <TextButton className="find-buyer-btn">
                  {t.formatMessage({ id: "find_buyers" })}
                </TextButton>
              </StyledATag>
            )}
          </Row>
          {!isMobile && (
            <>
              <SmText color={COLORS.gray[500]}>
                {t.formatMessage({ id: "or" })}
              </SmText>
              <SmText>
                {t.formatMessage({ id: "find_and_connect_potential_buyers" })}
              </SmText>
              <StyledATag href={PATHS.rfqMarket}>
                <GrayButton>
                  {t.formatMessage({ id: "find_buyers_now" })}
                </GrayButton>
              </StyledATag>
            </>
          )}
        </Stack>
      )}

      <Divider />

      <Grid container spacing={{ xs: 2, md: 4 }} py={2}>
        <Grid item xs={12} md={6}>
          <TextMd mb={1} className="title">
            {t.formatMessage({ id: questions.what_is_freshdi.title })}
          </TextMd>
          <SmText color={COLORS.gray[500]} className="description">
            {t.formatMessage({ id: questions.what_is_freshdi.description })}
          </SmText>

          <TextMd mb={1} mt={2} className="title">
            {t.formatMessage({
              id: questions.what_makes_freshdi_different.title,
            })}
          </TextMd>
          <SmText color={COLORS.gray[500]} className="description">
            {t.formatMessage({
              id: questions.what_makes_freshdi_different.description,
            })}
          </SmText>

          <TextMd mb={1} mt={2} className="title">
            {t.formatMessage({
              id: questions.ai_different.title,
            })}
          </TextMd>
          <ul>
            {questions.ai_different.descriptions.map((item, i) => (
              <li key={i}>
                <SmText color={COLORS.gray[500]} className="description">
                  {t.formatMessage({ id: item })}
                </SmText>
              </li>
            ))}
          </ul>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextMd mb={1} className="title">
            {t.formatMessage({
              id: questions.how_to_use_freshdi_ai.title,
            })}
          </TextMd>
          <ul>
            {questions.how_to_use_freshdi_ai.descriptions.map((item, i) => (
              <li key={i}>
                <SmText color={COLORS.gray[500]} className="description">
                  {t.formatMessage({ id: item })}
                </SmText>
              </li>
            ))}
          </ul>

          <TextMd mb={1} mt={2} className="title">
            {t.formatMessage({
              id: questions.q_and_a.title,
            })}
          </TextMd>
          <ul>
            {questions.q_and_a.descriptions.map((item, i) => (
              <li key={i}>
                <SmText color={COLORS.gray[500]} className="description">
                  {t.formatMessage({ id: item })}
                </SmText>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)(({ theme }) => ({
  ul: {
    paddingInlineStart: 16,
  },
  ".description": {
    textAlign: "justify",
  },

  [theme.breakpoints.down("md")]: {
    ".find-buyer-btn": {
      fontSize: 12,
      fontWeight: 500,
      color: COLORS.green[600],
    },
    ".title": {
      fontSize: 14,
    },
    ".description": {
      fontSize: 12,
    },
  },
}));

export default memo(FooterEnterprise);
