import { ButtonProps } from "@mui/material";
import { GrayButton } from "Components/Button";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { encodeBase64 } from "helpers/base64";
import { objectToQueryString } from "helpers/search";
import useNavigate from "hooks/useNavigate";
import { MouseEvent } from "react";
import { useIntl } from "react-intl";

type InquiryData = {
    farm_id?: string;
    farm_item_id?: string;
    unit?: string;
    quantity?: number | string;
  };
  

const SendInquiryButton = (
  props: ButtonProps & { data?: InquiryData; textId?: string }
) => {
  const { data, textId = "inquiry_now" } = props;
  const t = useIntl();
  const { history } = useNavigate();
  const onClickSendInquiry = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    let { farm_id, farm_item_id, quantity = "", unit = "" } = data || {};

    history.push({
      pathname: PATHS.sendInquiry,
      search: farm_id
        ? `${SEARCH_PARAMS.farmId}=${encodeBase64(farm_id)}${
            farm_item_id
              ? "&" +
                objectToQueryString({
                  [SEARCH_PARAMS.itemId]: encodeBase64(farm_item_id),
                  [SEARCH_PARAMS.quantity]: encodeBase64(quantity),
                  [SEARCH_PARAMS.unit]: encodeBase64(unit),
                })
              : ""
          }`
        : "",
    });
  };
  return (
    <GrayButton onClick={onClickSendInquiry} {...props}>
      {t.formatMessage({ id: textId })}
    </GrayButton>
  );
};

export default SendInquiryButton;
