import { styled, TooltipProps, Tooltip as MuiTooltip, tooltipClasses } from "@mui/material";

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: 5,
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0px 12px 16px -4px #10182808, 0px 4px 6px -2px #10182814",
      borderRadius: 8,
    },
  }));

  export default Tooltip