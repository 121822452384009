import { SVGProps } from "react";

export function CartIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clip-path="url(#clip0_16458_239340)">
        <path
          d="M1.15002 0.75H4.15002L6.16002 10.7925C6.22861 11.1378 6.41646 11.448 6.69068 11.6687C6.96491 11.8895 7.30805 12.0067 7.66002 12H14.95C15.302 12.0067 15.6451 11.8895 15.9194 11.6687C16.1936 11.448 16.3814 11.1378 16.45 10.7925L17.65 4.5H4.90002M7.90002 15.75C7.90002 16.1642 7.56424 16.5 7.15002 16.5C6.73581 16.5 6.40002 16.1642 6.40002 15.75C6.40002 15.3358 6.73581 15 7.15002 15C7.56424 15 7.90002 15.3358 7.90002 15.75ZM16.15 15.75C16.15 16.1642 15.8142 16.5 15.4 16.5C14.9858 16.5 14.65 16.1642 14.65 15.75C14.65 15.3358 14.9858 15 15.4 15C15.8142 15 16.15 15.3358 16.15 15.75Z"
          stroke="#008934"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16458_239340">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.400024)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
