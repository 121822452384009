import axios from "axios";
import { getConfigs } from "../Config";

export const configURL = getConfigs();

// API template
export const APITemplate = async (end_point, data, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: end_point,
  };
  return await axios
    .post(`${query_data.server}${query_data.end_point}`, data, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response?.data);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};



