import { Autocomplete, styled } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  "input.MuiAutocomplete-input": {
    padding: "0 !important",
  },
  ".MuiInputBase-root": {
    padding: 10,
  },
});

StyledAutocomplete.defaultProps = {
  noOptionsText: null,
  componentsProps: {
    popper: {
      sx: {
        "& .MuiAutocomplete-listbox": {
          fontSize: "14px",
        },
        "& .MuiAutocomplete-option": {
          fontSize: "14px",
        },
      },
    },
  },
};
