import { Box, Stack, styled } from "@mui/material";
import {
  DpTextSemiBold,
  ErrorText,
  Input,
  IntlMsg,
  PasswordInput,
  Text,
  YellowButton,
} from "Components";
import { useForm } from "react-hook-form";
import { apiUpdatePassword } from "services/api/user";
import { useLoading } from "providers/loading";
import useNavigate from "hooks/useNavigate";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { formatError } from "helpers/error";
import { useIntl } from "react-intl";
import { useNotify } from "providers/notify";

type Props = {};

const SetNewPassword = (props: Props) => {
  const t = useIntl();
  const { setNotify } = useNotify();
  const { setLoading } = useLoading();
  const { history, useSearchParams } = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    defaultValues: {
      key: useSearchParams.get(SEARCH_PARAMS.key),
      password: "",
      repassword: "",
    },
    mode: "onChange",
  });

  const onHandleSubmit = async (data: any) => {
    const { password, phone_number, key } = data;
    setLoading(true);
    await apiUpdatePassword({
      key,
      phone_number,
      new_password: password,
      logout_all_sessions: 1,
    })
      .then((res) => {
        setLoading(false);
        history.push(PATHS.login);
        setNotify({
          open: true,
          type: "success",
          msg: t.formatMessage({ id: "Msg.UpdatedSuccessfully" }),
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setError("root", {
          message: formatError(err)?.message,
        });
      });
  };

  return (
    <Main px={2} py={4} className="main">
      <Box maxWidth={574} p={3}>
        <Stack spacing={{ xs: 2, md: 4 }}>
          <div>
            <DpTextSemiBold className="step-title">
              {t.formatMessage({ id: "set_new_password" })}
            </DpTextSemiBold>
            <Text className="step-desc">
              {t.formatMessage({ id: "set_new_password_desc" })}
            </Text>
          </div>

          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <Stack spacing={2}>
              <PasswordInput
                toplabel={t.formatMessage({ id: "new_password" })}
                {...register("password", {
                  required: {
                    value: true,
                    message: t.formatMessage({ id: "field_requied" }),
                  },
                })}
                placeholder={t.formatMessage({
                  id: "enter_your_new_password",
                })}
                error={!!errors?.password}
                helperText={errors?.password?.message}
                requiredlabel
              />

              <PasswordInput
                toplabel={t.formatMessage({ id: "confirm_password" })}
                {...register("repassword", {
                  required: {
                    value: true,
                    message: t.formatMessage({ id: "field_requied" }),
                  },
                  validate: (value) =>
                    value === getValues("password") ||
                    t.formatMessage({ id: "repassword_incorrect" }),
                })}
                error={!!errors?.repassword}
                placeholder={t.formatMessage({
                  id: "confirm_your_new_password",
                })}
                helperText={errors?.repassword?.message}
                requiredlabel
              />

              {!!errors.root && <ErrorText>{errors.root?.message}</ErrorText>}

              <Box pt={2}>
                <YellowButton
                  className="submit-btn"
                  type="submit"
                  disabled={!isValid}
                >
                  <IntlMsg id={"reset"} />
                </YellowButton>
              </Box>
            </Stack>
          </form>
        </Stack>
      </Box>
    </Main>
  );
};

const Main = styled(Stack)({});

export default SetNewPassword;
