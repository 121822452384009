import { dateDiff } from "helpers/datetime";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

type Props = {
  startTime: string;
  endTime: string;
  hideAgo?: boolean;
};

type TimeType = { type: string; value: number };

const TimeDiff = ({ startTime, endTime, hideAgo }: Props) => {
  const t = useIntl();
  const nowTimeDiff = [{ type: "just_now", value: 0 }];

  const [timediff, setDatediff] = useState<TimeType[]>([]);
  useEffect(() => {
    let dateDiffObj = {};

    try {
      dateDiffObj = dateDiff(startTime, endTime);
    } catch (error) {
      console.log(error);
      return;
    }

    type keyType = keyof typeof dateDiffObj;
    const objKeys = Object.keys(dateDiffObj)
      .filter((key: string) => dateDiffObj[key as keyType] > 0)
      .map((key) => ({ type: key, value: dateDiffObj[key as keyType] }));

    setDatediff(objKeys.length > 0 ? objKeys : []);
  }, [startTime, endTime]);

  return (
    <>
      {(timediff.length === 0 ? nowTimeDiff : timediff).map(
        ({ value, type }) =>
          (value > 0 ? value + " " : "") +
          (t.formatMessage({
            id: value > 1 ? `${type}s` : type,
          }) +
            " ")
      )}

      {timediff.length > 0 && !hideAgo
        ? " " +
          t.formatMessage({
            id: "ago",
          })
        : ""}
    </>
  );
};

export default TimeDiff;
