import { Button, IconButton, styled } from "@mui/material";
import { COLORS } from "constants/layout";

export const StyledTextButton = styled(Button)({
  textTransform: "initial",
  padding: 0,
  fontWeight: 600,
  fontSize: 16,
  color: "var(--gray-color)",
  // ":hover": {
  //   fontWeight: 700,
  // },
  ".MuiButton-startIcon": {
    marginLeft: 0,
  },
});

export const ButtonBase = styled(Button)({
  textTransform: "initial",
  borderRadius: 8,
  fontWeight: "500",
  padding: "6px 16px",
  fontSize: 14,
});

export const StyledButton = styled(Button)({
  padding: "10px 16px",
  border: "1px solid rgba(0,0,0,.26)",
  textTransform: "initial",
  color: "#000",
  justifyContent: "center",
  display: "flex",
  gap: 12,
  alignItems: "center",
  "& img": {
    width: 24,
  },
});

export const StyledConfirmButton = styled(Button)({
  borderRadius: 8,
  width: "100%",
  textTransform: "initial",
  padding: "8px 14px",
  color: "#fff",
  "&": {
    background: "var(--color)",
  },
  ":hover": {
    background: "var(--yellow-600)",
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "#D0D5DD",
  },
  fontWeight: 600,
});

export const StyledCancelButton = styled(ButtonBase)({
  color: "#000",
  border: "1px solid #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});

const StyledAcceptButton = styled(ButtonBase)({
  color: "#fff",
  fontWeight: 600,
  "&,:hover": {
    backgroundColor: "var(--sub-color)",
  },
  "&.Mui-disabled": {
    backgroundColor: "#D0D5DD",
    color: "#fff",
  },
});

export const StyledLgAcceptButton = styled(StyledAcceptButton)(({ theme }) => ({
  fontWeight: 600,
  padding: "10px 18px",
  "&:hover": {
    backgroundColor: "var(--green-800)",
  },
  ".MuiButton-endIcon": {
    marginLeft: 4,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const StyledBaseButton = styled(ButtonBase)({
  color: "#000",
  border: "1px solid #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});

export const StyledAddButton = styled(StyledBaseButton)({
  color: "#fff",
  "&,:hover": {
    backgroundColor: "var(--sub-color)",
  },
  span: {
    marginRight: 4,
  },
});

export const StyledReportButton = styled(StyledBaseButton)({
  svg: {
    width: 20,
    height: 20,
  },
  color: "var(--gray)",
  width: "fit-content",
  lineHeight: "20px",
});

export const StyledMailButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  zIndex: 100,
  right: 2,
  bottom: 64,
  "&,:hover": {
    backgroundColor: "var(--green-500)",
  },
  width: 40,
  height: 40,
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.down("md")]: {
    // right: 8,
  },
}));

export const StyledGrayButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: COLORS.gray[100],
  border: `1px solid ${COLORS.green[600]}`,
  color: COLORS.green[600],
  "&:disabled": {
    backgroundColor: "#E5E5E5",
    color: "#fff",
    borderColor: "#E5E5E5",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const StyledWhiteButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${COLORS.gray[800]}`,
  color: COLORS.gray[900],

  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    ".MuiButton-icon svg": {
      maxHeight: 21,
    },
  },
}));

export const StyledGetQuotesButton = styled(ButtonBase)({
  fontSize: 14,
  fontWeight: 500,
  color: COLORS.yellow[500],
  padding: 6,
});

export const StyledRegisterButton = styled(Button)({
  "&,:hover": {
    backgroundColor: "var(--app-sub-bg-color)",
  },
  fontWeight: 700,
  fontSize: 14,
  color: "#fff",
  textTransform: "initial",
});
