import { ItemPriceType } from "types/item";
import { Stack, Typography, styled } from "@mui/material";
import { formatCurrencyWithUnit } from "helpers/string";
import { useIntl } from "react-intl";

type Props = {
  prices: ItemPriceType[];
  selectedPrice?: ItemPriceType;
  setSelectedPrice?: (price: ItemPriceType) => void;
};

const ItemPrices = ({ prices, selectedPrice, setSelectedPrice }: Props) => {
  const t = useIntl();
  return (
    <StyleStack direction="row" gap={2} alignItems="flex-start" mb={2}>
      {prices.map((offer_price: ItemPriceType, i: number) => {
        let {
          price_discount,
          price,
          quantity_min,
          quantity_max,
          name,
          weight,
        } = offer_price;
        let showDiscountPrice = price > price_discount && price_discount > 0;
        return (
          <Stack key={i} spacing="4px">
            <Typography className="limit">
              {quantity_min > 0 &&
                `${quantity_min}${
                  quantity_max > quantity_min ? `-${quantity_max} ` : " "
                }`}
              {weight || ""}
            </Typography>

            <Typography
              onClick={() => setSelectedPrice?.(offer_price)}
              className={`price ${
                selectedPrice?.name === name ? "selected" : ""
              }`}
            >
              {price <= 0
                ? t.formatMessage({ id: "to_be_negotiated" })
                : `${formatCurrencyWithUnit(
                    showDiscountPrice ? price_discount : price
                  )}`}
            </Typography>
            {showDiscountPrice && (
              <Typography className="origin_price">
                ${formatCurrencyWithUnit(price)}
              </Typography>
            )}
          </Stack>
        );
      })}
    </StyleStack>
  );
};

const StyleStack = styled(Stack)({
  flexWrap: "wrap",
  ".limit": {
    color: "rgba(0, 0, 0, 0.5)",
  },
  ".price": {
    fontSize: 24,
    cursor: "pointer",
    fontWeight: 500,
    "&.selected": {
      color: "var(--color)",
    },
  },
  ".origin_price": {
    color: "#FD853A",
    textDecoration: "line-through",
  },
});

export default ItemPrices;
