import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE_MB } from "constants/layout";

export const IMAGE_TYPES = ["png", "jpg", "jpeg", "webp"];

export const checkFileIsImage = (fileUrl?: string) => {
  let fileType = (fileUrl || "").split(".").pop();
  return fileType && IMAGE_TYPES.indexOf(fileType) > -1;
};

export const createDownloadLink = (
  url: string,
  filename: string,
  callback?: () => void
) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  callback?.();
};

export const isValidFile = (file: File) => {
  if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    alert(
      "Invalid file type. Only jpg, jpeg, png, doc, xls, or pdf are allowed."
    );
    return false;
  }

  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    alert("File size exceeds the 10MB limit.");
    return false;
  }

  return true;
};
