export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language;
  switch (browserLanguage) {
    case "en-US":
      return "en";
    case "vi":
      return "vi";

    default:
      return "en";
  }
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export const scrollToTopOfElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }
};
