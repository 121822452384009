import { SVGProps } from "react";

export function IdeaIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.75184 16.3125H11.3223M4.00984 5.56237C4.66084 3.13237 6.52121 1.6875 9.03709 1.6875C11.553 1.6875 13.4133 3.132 14.0643 5.56237C14.7153 7.99275 13.5011 10.4842 11.3223 11.7424V14.4844H6.75184V11.7424C3.88309 10.3721 3.35846 7.99237 4.00984 5.56237Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.35495 6.86093C5.35495 6.86093 5.0602 3.6363 9.06295 3.11768"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
