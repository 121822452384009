import {
  Box,
  styled,
  tooltipClasses,
  Tooltip as TooltipLib,
  TooltipProps,
} from "@mui/material";
import {
  CIRCLE_CROWN_ICON,
  InfoIcon,
  VERIFIED2_ICON,
} from "Assets/icons";
import { ReactNode, useMemo } from "react";
import { Img } from "./Img";
import { useIntl } from "react-intl";
import { Text } from "./Text";
import { VERIFICATION_LEVELS } from "constants/data";

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipLib {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
    borderRadius: 8,
  },
}));

export const VerifyTooltip = ({
  title,
  children,
}: {
  title: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <Tooltip title={title}>
      <StyledDiv className="tooltip-icon">
        {children || (
          <Img src={VERIFIED2_ICON} alt="icon" className="verified-icon" />
        )}
      </StyledDiv>
    </Tooltip>
  );
};

export const VerifySupplierTooltip = ({
  title,
  children,
  level,
}: {
  title: ReactNode;
  children?: ReactNode;
  level?: string;
}) => {
  if (!level || level === VERIFICATION_LEVELS.level1.title) return null;

  return (
    <Tooltip title={title || <LevelTooltip level={level} />}>
      <StyledDiv className="tooltip-icon">
        {children || (
          <Img
            src={VERIFIED2_ICON}
            alt="icon"
            className="verified-icon"
            height={16}
          />
        )}
      </StyledDiv>
    </Tooltip>
  );
};

export const CrownTooltip = ({
  title,
  children,
}: {
  title: ReactNode;
  children?: any;
}) => {
  return (
    <Tooltip title={title}>
      <StyledDiv className="tooltip-icon">
        {children || (
          <Img
            src={CIRCLE_CROWN_ICON}
            alt="icon"
            className="crown-icon"
            width={12}
          />
        )}
      </StyledDiv>
    </Tooltip>
  );
};

export const LevelTooltip = ({
  level,
  title = "supplier_is_verified_by_freshdi",
}: {
  level?: string;
  title?: string;
}) => {
  const t = useIntl();

  const levelTexts = useMemo(
    () =>
      level
        ? [
            {
              label: "verify_work_email",
              show: ["Level 2", "Level 3", "Level 4"].indexOf(level) > -1,
            },
            {
              label: "verify_business_documents",
              show: ["Level 3", "Level 4"].indexOf(level) > -1,
            },
            {
              label: "smart_trace_online",
              show: ["Level 4"].indexOf(level) > -1,
            },
          ]
        : [],
    [level]
  );

  const isVerified = useMemo(
    () => level && level !== VERIFICATION_LEVELS.level1.title,
    [level]
  );

  return (
    <StyledLevelTooltip>
      <Text>
        {t.formatMessage({
          id: isVerified ? title : "unverified",
        })}
      </Text>
      {isVerified && (
        <ul>
          {levelTexts
            .filter((text) => text.show)
            .map((text) => (
              <li>
                <Text>{t.formatMessage({ id: text.label })}</Text>
              </li>
            ))}
        </ul>
      )}
    </StyledLevelTooltip>
  );
};

export const VerificationTooltip = ({
  data,
  title = "supplier_is_verified_by_freshdi",
}: {
  data?: { is_verified_work_email?: boolean; is_biz_verified?: boolean };
  title?: string;
}) => {
  const t = useIntl();

  const levelTexts = useMemo(
    () => [
      {
        label: "verify_work_email",
        show: data?.is_verified_work_email,
      },
      {
        label: "verify_business_documents",
        show: data?.is_biz_verified,
      },
    ],
    [data]
  );

  return (
    <StyledLevelTooltip>
      <Text>{t.formatMessage({ id: title })}</Text>
      <ul>
        {levelTexts
          .filter((text) => text.show)
          .map((text) => (
            <li>
              <Text>{t.formatMessage({ id: text.label })}</Text>
            </li>
          ))}
      </ul>
    </StyledLevelTooltip>
  );
};

export const ComingSoonTooltip = () => {
  const t = useIntl();
  return (
    <Tooltip title={t.formatMessage({ id: "coming_soon" })}>
      <StyledDiv className="tooltip-icon">{<InfoIcon />}</StyledDiv>
    </Tooltip>
  );
};

export const InfoTooltip = ({ msg = "" }: { msg: string }) => {
  const t = useIntl();
  return (
    <Tooltip title={t.formatMessage({ id: msg })}>
      <StyledDiv className="tooltip-icon">{<InfoIcon />}</StyledDiv>
    </Tooltip>
  );
};

const StyledLevelTooltip = styled(Box)({
  p: {
    fontSize: 10,
  },
  ul: {
    paddingInlineStart: 10,
  },
  "& ul li p": {},
});

const StyledDiv = styled("div")`
  width: fit-content;
  display: flex;
  align-items: center;
  img {
    min-width: 16px;
  }
  .lazy-load-image-background {
    display: flex !important;
  }
`;
