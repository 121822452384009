import { Grid, Stack, styled } from "@mui/material";
import React, { ReactNode, Suspense, useMemo } from "react";
import { Sidebar } from "./components";
import { Breadcrumbs } from "Components";
import { useIntl } from "react-intl";
import { PATHS } from "constants/routes";
import { useLocation } from "react-router-dom";

type Props = {
  children?: ReactNode;
};

const HelperLayout = ({ children }: Props) => {
  const t = useIntl();
  const { pathname } = useLocation();

  const currentPath = useMemo(() => {
    const pathParts = pathname.split("/");
    const path = pathParts[pathParts.length - 1];
    return path.replaceAll("-", "_");
  }, [pathname]);

  return (
    <StyledStack>
      <Breadcrumbs
        data={[
          {
            label: t.formatMessage({ id: "home" }),
            href: PATHS.home,
          },
          {
            label: t.formatMessage({ id: currentPath }),
            href: "",
          },
        ]}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12 / 5}>
          <Sidebar />
        </Grid>

        <Grid item xs={12} md={(12 * 4) / 5} minHeight="50vh">
          <Suspense fallback={null}>{children}</Suspense>
        </Grid>
      </Grid>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.43,

  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export default HelperLayout;
