import { Stack, styled } from "@mui/material";
import { Img } from "../Img";
import { SmTextMd, StyledA, XsText } from "../Text";
import { DIRECT_PATHS } from "constants/routes";
import TimeDiff from "../TimeDiff";
import dayjs from "dayjs";
import CountryFlag from "../CountryFlag";
import { useIntl } from "react-intl";
import Row from "../Row";
import { ElementType } from "react";
import { BuyerInfosType } from "types/buyer";
import { COLORS } from "constants/layout";
import { formatImg } from "utils/helpers";
import BuyerVerfifyTooltip from "Components/tooltip/BuyerVerifyTooltip";

const BaseBuyerInfors = ({
  company,
  nameComponent = "p",
}: {
  company?: BuyerInfosType;
  nameComponent?: ElementType;
}) => {
  const t = useIntl();
  const {
    customer_name,
    country_code,
    last_active,
    customer_id = "",
    country,
    company_logo,
  } = company || {};
  const NOW = dayjs();

  return (
    <StyledBaseBuyerInfors direction="row" alignItems="flex-start" spacing={1}>
      {company_logo && (
        <Img
          src={formatImg(company_logo, 1)}
          alt="img"
          className="company_img"
        />
      )}
      <Stack
        spacing={0.5}
        flex={1}
        maxWidth={`calc(100% - ${company_logo ? "48px" : "0px"})`}
      >
        <StyledA href={DIRECT_PATHS.buyerDetail(customer_id)}>
          <SmTextMd
            className="company_name ellipsis-text"
            component={nameComponent}
          >
            {customer_name}
          </SmTextMd>
        </StyledA>

        <Row spacing={0.5} mb={0.5} minHeight={20}>
          <BuyerVerfifyTooltip data={{ is_biz_verified: 1 }} />
          <CountryFlag {...{ country_code, country }} />
        </Row>

        {last_active && (
          <XsText color={COLORS.gray[600]}>
            {t.formatMessage(
              { id: "active_time" },
              {
                time: (
                  <TimeDiff
                    endTime={NOW.format()}
                    startTime={dayjs(last_active).format()}
                  />
                ),
              }
            )}
          </XsText>
        )}
      </Stack>
    </StyledBaseBuyerInfors>
  );
};

const StyledBaseBuyerInfors = styled(Stack)({
  fontSize: 16,
  width: "100%",
  ".company_name": {
    color: "#1D2939",
  },
  ".company_img": {
    width: 40,
    height: 40,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".flag": {
    width: 16,
    height: 16,
  },
  ".active-text": {
    color: "var(--gray-600)",
  },
});

export default BaseBuyerInfors;
