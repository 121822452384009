import { Box, styled } from "@mui/material";
import { Link, SmText } from "Components";
import { SCROLL_TOP_POSITIONS } from "constants/layout";
import { PATHS } from "constants/routes";
import { memo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

type Props = {};

const Sidebar = (props: Props) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const tabs = [
    { label: "terms_of_use", value: PATHS.termCondition },
    { label: "sitemap", value: PATHS.sitemap },
    { label: "privacy_policy", value: PATHS.policy },
    {
      label: "intellectual_property_policy",
      value: PATHS.intellectualPropertyPolicy,
    },
    { label: "disclaimers", value: PATHS.disclaimers },
  ];

  return (
    <StyledSidebar p={2} className="card">
      {tabs.map(({ value, label }) => (
        <Link key={value} to={value}>
          <SmText className={pathname.indexOf(value) > -1 ? "selected" : ""}>
            {intl.formatMessage({ id: label })}
          </SmText>
        </Link>
      ))}
    </StyledSidebar>
  );
};

const StyledSidebar = styled(Box)({
  position: "sticky",
  top: SCROLL_TOP_POSITIONS.desktopHeader + 16,
  p: {
    padding: "10px 16px",
    cursor: "pointer",

    "&.selected": {
      backgroundColor: "#EDFFF4",
    },
  },
});

export default memo(Sidebar);
