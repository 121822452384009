import { SVGProps } from "react";

export function SuccessfulIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={19}
      viewBox={"0 0 19 19"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M17 8.81002V9.50002C16.9991 11.1173 16.4754 12.691 15.507 13.9864C14.5386 15.2818 13.1775 16.2294 11.6265 16.6879C10.0756 17.1465 8.41794 17.0914 6.90085 16.531C5.38376 15.9705 4.08849 14.9346 3.20822 13.5778C2.32795 12.221 1.90984 10.6161 2.01626 9.00226C2.12267 7.38844 2.74791 5.85227 3.79871 4.62283C4.84951 3.39338 6.26959 2.53656 7.84714 2.18013C9.42469 1.8237 11.0752 1.98677 12.5525 2.64502M17 3.50002L9.5 11.0075L7.25 8.75752"
        stroke="#039855"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
