import { Box, styled } from "@mui/material";
import { Text } from "Components/Text";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import Tooltip from "./Tooltip";
import { Img } from "Components/Img";
import { VERIFIED2_ICON } from "Assets/icons";

const SupplierVerfifyTooltip = ({
  data,
  title = "supplier_is_verified_by_freshdi",
}: {
  data?: { is_biz_verified?: number; is_verified_work_email?: number };
  title?: string;
}) => {
  const t = useIntl();

  const levelTexts = useMemo(
    () =>
      [
        {
          label: "verify_work_email",
          show: data?.is_verified_work_email,
        },
        {
          label: "verify_business_documents",
          show: data?.is_biz_verified,
        },
      ].filter((text) => text.show),
    [data]
  );

  if (levelTexts.length === 0) return null;

  return (
    <Tooltip
      title={
        <StyledLevelTooltip>
          <Text>{t.formatMessage({ id: title })}</Text>
          <ul>
            {levelTexts.map((text) => (
              <li>
                <Text>{t.formatMessage({ id: text.label })}</Text>
              </li>
            ))}
          </ul>
        </StyledLevelTooltip>
      }
    >
      <div>
        <Img src={VERIFIED2_ICON} alt="icon" height={12}/>
      </div>
    </Tooltip>
  );
};

const StyledLevelTooltip = styled(Box)({
  p: {
    fontSize: 10,
  },
  ul: {
    paddingInlineStart: 10,
  },
  "& ul li p": {},
});

export default SupplierVerfifyTooltip;
