import { SVGProps } from "react";

export function NoteIcon({
  width = 18,
  height = 22,
  viewBox = "0 0 18 22",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7M11 1L17 7M11 1V7H17M13 12H5M13 16H5M7 8H5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmNoteIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 15 16"
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M8.75 1.75H3.75C3.41848 1.75 3.10054 1.8817 2.86612 2.11612C2.6317 2.35054 2.5 2.66848 2.5 3V13C2.5 13.3315 2.6317 13.6495 2.86612 13.8839C3.10054 14.1183 3.41848 14.25 3.75 14.25H11.25C11.5815 14.25 11.8995 14.1183 12.1339 13.8839C12.3683 13.6495 12.5 13.3315 12.5 13V5.5M8.75 1.75L12.5 5.5M8.75 1.75V5.5H12.5M10 8.625H5M10 11.125H5M6.25 6.125H5"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
