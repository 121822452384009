import { Box, BoxProps, styled } from "@mui/material";
import { CancelButton, GrayButton } from "Components/Button";
import { useIntl } from "react-intl";

const ConfirmButtons = ({
  cancleText = "Cancel",
  onCancle = () => {},
  confirmText = "agree",
  onConfirm = () => {},
  sx,
}: {
  cancleText?: string;
  onCancle?: () => void;
  confirmText?: string;
  onConfirm?: () => void;
  sx?: BoxProps;
}) => {
  const intl = useIntl();
  return (
    <Allbtnbox {...{ sx }}>
      <CancelButton onClick={onCancle} className="cancleBtn">
        {intl.formatMessage({ id: cancleText })}
      </CancelButton>
      <GrayButton className="confirmBtn" onClick={onConfirm}>
        {intl.formatMessage({ id: confirmText })}
      </GrayButton>
    </Allbtnbox>
  );
};

const Allbtnbox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: 16,
});

export default ConfirmButtons;
