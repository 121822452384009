import { Box, Stack, styled } from "@mui/material";
import { Img } from "./Img";
import { CompanyType } from "types";
import { formatImgUrl } from "utils/helpers";
import { forwardRef, useMemo, useRef } from "react";
import { Link, XsText } from "./Text";
import { useIntl } from "react-intl";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import defaultImage from "Assets/images/default_img.webp";
import { followRel } from "helpers/format";
import { COLORS } from "constants/layout";
import { BaseSupplierInfors } from "./BaseSupplierInfors";

type Props = {
  company?: CompanyType;
  directFull?: boolean;
};

const Company = forwardRef(({ company }: Props, ref: any) => {
  const intl = useIntl();
  const {
    slug = "",
    gallery_preview,
    no_follow = 0,
    business_types,
    export_countries,
  } = company || {};

  const swiperRef = useRef<any>(null);

  const handleSlideChange = (swiper: any) => {
    swiperRef.current.swiper.allowTouchMove =
      swiper.isBeginning && swiper.isEnd;
  };

  const showRealMedia = useMemo(
    () => gallery_preview && gallery_preview.length > 0,
    [gallery_preview]
  );

  const medias = useMemo(
    () => (showRealMedia ? gallery_preview : [defaultImage]),
    [showRealMedia]
  );

  const supplierFields = useMemo(
    () => [
      { label: "business_type", value: business_types },
      { label: "export_market", value: export_countries },
    ],
    [business_types, export_countries]
  );

  return (
    <StyledCompany ref={ref}>
      <Link to={`/${slug}`} rel={followRel(no_follow)}>
        <Swiper
          onSlideChange={handleSlideChange}
          className="medias"
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
        >
          {medias?.map((media, i) => (
            <SwiperSlide key={i}>
              <Img
                src={showRealMedia ? formatImgUrl(media, 3) : media}
                alt="media"
                height="100%"
                width="100%"
                style={{ objectFit: "cover" }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Link>

      <Stack className="info_box" spacing={1}>
        <BaseSupplierInfors {...{ company }} />

        {export_countries && (
          <XsText
            className="ellipsis-text"
            color={COLORS.green[500]}
            component="h3"
            height={18}
          >
            {export_countries && intl.formatMessage({ id: "products" }) + ": "}
            {export_countries}
          </XsText>
        )}

        {supplierFields
          .filter((field) => field.value)
          .map((field, i) => (
            <XsText
              className="ellipsis-text field-text"
              color={COLORS.gray[500]}
              key={i}
            >
              {field.value && intl.formatMessage({ id: field.label }) + ": "}
              <span>{field.value}</span>
            </XsText>
          ))}
      </Stack>
    </StyledCompany>
  );
});

const StyledCompany = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: "#fff",
  overflow: "hidden",
  position: "relative",
  border: "1px solid rgba(0, 0, 0, 0.15)",
  height: 330,
  width: "100%",
  "& .medias": {
    height: 182,
    img: {
      width: "100%",
      height: "100%",
    },
  },
  "& .info_box": {
    padding: 8,
  },
  ".field-text": {
    span: {
      color: COLORS.gray[900],
    },
    height: 18,
  },

  "& .swiper-pagination-bullet-active": {
    backgroundColor: "#ddd",
  },
  ".company_name": {
    fontSize: 12,
    fontWeight: 500,
  },
  ".plan-chip": {
    height: 14,
  },
  ".verified-icon": {
    height: 12,
  },

  [theme.breakpoints.down("md")]: {
    "& .image": {},
    "& .send-inquiry-btn": {
      fontSize: 12,
      padding: 4,
    },
  },
}));

export default Company;
