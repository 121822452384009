import { DependencyList, useEffect, useMemo, useState } from "react";

const useGetData = ({
  promise,
  deps = [],
  timeout = 0, // Default timeout is 0 milliseconds
}: {
  promise?: any;
  deps?: DependencyList;
  timeout?: number; // Timeout in milliseconds between API calls
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();

  const fetchData = async () => {
    try {
      if (timeout > 0) {
        await new Promise((resolve) => setTimeout(resolve, timeout)); // Delay for the specified timeout
      }
      const response = await promise?.();
      setData(response?.data?.message);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [...deps]);

  return useMemo(
    () => ({
      isLoading,
      data,
      error,
    }),
    [isLoading, data, error]
  );
};

export default useGetData;
