import { Skeleton, Stack, styled } from "@mui/material";

const ProductSkeleton = () => {
  return (
    <StyledProductSkeleton>
      <Skeleton variant="rounded" height={195} />
      <Stack p={1} spacing={1}>
        <Skeleton variant="text" height={20} width={148} />
        <Skeleton variant="text" height={20} width={60} />
        <Skeleton variant="text" height={20} width={96} />
        <Skeleton variant="text" height={20} width={148} />
        <Skeleton variant="text" height={20} width="70%" />
      </Stack>
    </StyledProductSkeleton>
  );
};

const StyledProductSkeleton = styled(Stack)({
  height: "100%",
  backgroundColor: "#fff",
  borderRadius: 8,
  border: "1px solid #eaecf0",
});

export default ProductSkeleton;
